// * React Hooks
import React, { useEffect } from 'react'
// * Style
import './scss/style.scss'

import { BrowserRouter } from 'react-router-dom'

// * Application Router Component
import Router from './Router/Router'

import { getCSRFToken, setCSRFToken } from './helpers'
import { fetchCSRFToken } from './api/csrf'

// * React tostify for notifications.
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// * API Handler Initialization
import { QueryClient, QueryClientProvider } from 'react-query'
import { AuthContextProvider } from './helpers/AuthContext'
import { Web3AuthContextProvider } from './helpers/Web3AuthContext'

const queryClint = new QueryClient()

function App() {
  useEffect(() => {
    async function checkCSRFToken() {
      console.log('calling csrf')
      const CSRF_TOKEN = await getCSRFToken()
      if (!CSRF_TOKEN) {
        const csrf_token = await fetchCSRFToken()
        setCSRFToken(csrf_token)
      }
    }
    checkCSRFToken()
  }, [])

  return (
    <BrowserRouter>
      <div className="App">
        <QueryClientProvider client={queryClint}>
          <AuthContextProvider>
            <Web3AuthContextProvider>
              <Router />
            </Web3AuthContextProvider>
          </AuthContextProvider>
          <ToastContainer />
        </QueryClientProvider>
      </div>
    </BrowserRouter>
  )
}

export default App
