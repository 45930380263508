// * Errors Type
export const EMAIL_ERROR_TYPE = 'emailError'
export const PASSWORD_ERROR_TYPE = 'passwordError'
export const USERNAME_ERROR_TYPE = 'usernameError'

// * Error Messages
// ** Email Validation Errors
export const NULL_EMAIL_ERROR = 'Email is required.'
export const INVALID_EMAIL_ERROR = 'Invalid email address.'
// ** Password Validation Errors
export const NULL_PASSWORD_ERROR = 'Password is required.'
// * Username Validation Errors
export const USERNAME_NULL_ERROR = 'Username is required.'
export const INVALID_USERNAME_ERROR = 'Invalid username.'

// export const PASSWORD_LENGTH_ERROR = 'Password should be atleast 8 character long.'

export const AVATAR_API = 'https://ui-avatars.com/api'
export const API = 'http://localhost:1337/api'
export const AUTH_TOKEN = 'authToken'
export const BEARER = 'Bearer'

export const CSRF_TOKEN = 'csrfToken'
