export const Register = '/register'
export const Login = '/login'
export const Page404 = '/404'
export const Page500 = '/505'
export const Dashboard = '/dashboard'

export const CoursesList = '/course/list'
export const AddCourse = '/course/add'
export const ViewCourse = '/course/view/:id'
export const EditCourse = '/course/edit'
export const EditCourseId = '/course/edit/:id'

export const UserList = '/user/list'
export const AddUser = '/user/add'

export const PassagesList = '/inspirational/list'
export const AddPassage = '/passage/add'
export const EditPassage = '/passage/edit'
export const EditPassageId = '/passage/edit/:id'

export const EventsList = '/event/list'
export const AddEvent = '/event/add'
export const EditEvent = '/event/edit'
export const EditEventId = '/event/edit/:id'

export const SessionList = '/session/list'
export const AddSession = '/session/add'
export const EditSession = '/session/edit'
export const EditSessionId = '/session/edit/:id'

export const MentorList = '/mentoring/list'
export const AddMentor = '/mentoring/add'
export const EditMenotr = '/mentoring/edit'
export const EditMentorId = '/mentoring/edit/:id'

export const QuizList = '/quiz/list'
export const AddQuiz = '/quiz/add'
export const EditQuiz = '/quiz/edit'
export const EditQuizId = '/quiz/edit/:id'

export const LearningList = '/daily-learning/list'
export const AddLearning = '/daily-learning/add'
export const EditLearning = '/daily-learning/edit'
export const EditLeanringId = '/daily-learning/edit/:id'

export const FreeCourseList = '/free-course/list'
export const AddFreeCourse = '/free-course/add'
export const EditFreeCourse = '/free-course/edit'
export const EditFreeCourseId = '/free-course/edit/:id'

export const CoursesRequestList = '/course/request/list'
export const CreatePreSale = '/presale/add'
export const CreatePreSaleId = '/presale/add/:id'

export const CreateAllowList = '/allowlist/add'
export const CreateAllowListId = '/allowlist/add/:id'

export const Transactions = '/transactions'
