// * React Hooks
import React, { lazy, Suspense, useEffect } from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom'

// * Routes
import * as URL from './Routes'
import ProtectedRoute from 'src/helpers/ProtectedRoute'

// * Pages
const Login = lazy(() => import('../views/pages/login/Login'))
const Register = lazy(() => import('../views/pages/register/Register'))
const Page404 = lazy(() => import('../views/pages/page404/Page404'))
const Page505 = lazy(() => import('../views/pages/page500/Page500'))
const Dashboard = lazy(() => import('../views/dashboard/Dashboard'))

const UserList = lazy(() => import('../views/pages/Users/List'))
const AddUser = lazy(() => import('../views/pages/Users/Add/AddUser'))

const CoursesList = lazy(() => import('../views/pages/Course/List'))
const CourseView = lazy(() => import('../views/pages/CourseView/CourseView'))
const AddCourse = lazy(() => import('../views/pages/AddCourse/AddCourse'))
const EditCourse = lazy(() => import('../views/pages/EditCourse/EditCourse'))

const PassagesList = lazy(() => import('../views/pages/Inspirational/list/List'))
const AddPassage = lazy(() => import('../views/pages/Inspirational/add/AddPassage'))
const EditPassage = lazy(() => import('../views/pages/Inspirational/edit/EditPassage'))

const EventList = lazy(() => import('../views/pages/Events/list/List'))
const AddEvent = lazy(() => import('../views/pages/Events/add/AddEvent'))
const EditEvent = lazy(() => import('../views/pages/Events/edit/EditEvent'))

const SessionList = lazy(() => import('../views/pages/Sessions/list/List'))
const AddSession = lazy(() => import('../views/pages/Sessions/add/AddSession'))
const EditSession = lazy(() => import('../views/pages/Sessions/edit/EditSession'))

const MentorList = lazy(() => import('../views/pages/Mentorship/list/List'))
const AddMentor = lazy(() => import('../views/pages/Mentorship/add/AddMentor'))
const EditMentor = lazy(() => import('../views/pages/Mentorship/edit/EditMentor'))

const QuizList = lazy(() => import('../views/pages/Quiz/list/List'))
const AddQuiz = lazy(() => import('../views/pages/Quiz/add/AddQuiz'))
const EditQuiz = lazy(() => import('../views/pages/Quiz/edit/EditQuiz'))

const LearningList = lazy(() => import('../views/pages/DailyLearning/list/List'))
const AddLearning = lazy(() => import('../views/pages/DailyLearning/add/AddLearning'))
const EditLearning = lazy(() => import('../views/pages/DailyLearning/edit/EditLearning'))

const FreeCourseList = lazy(() => import('../views/pages/FreeCourse/list/List'))
const AddFreeCourse = lazy(() => import('../views/pages/FreeCourse/add/AddFreeCourse'))
const EditFreeCourse = lazy(() => import('../views/pages/FreeCourse/edit/EditFreeCourse'))

const CourseRequestList = lazy(() => import('../views/pages/Course/requests/List'))
const CreatePreSale = lazy(() => import('../views/pages/Course/presale/AddPreSale'))
const CreateAllowList = lazy(() => import('../views/pages/Course/allowlist/AddAllowlist'))

const Transactions = lazy(() => import('../views/pages/Transactions/List'))

// * Page Layout Component
const Layout = lazy(() => import('../layout/Layout'))

// * Loader
const Loader = () => (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

export default function Router() {
  const Navigate = useNavigate()
  useEffect(() => {
    if (window.location.pathname === '/') Navigate('/login')
  })
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path={URL.Login}
          element={
            <ProtectedRoute accessBy="non-authenticated">
              <Login />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.Dashboard}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<Dashboard />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.CoursesList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<CoursesList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddCourse}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddCourse />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditCourseId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditCourse />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.UserList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<UserList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddUser}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddUser />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.ViewCourse}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<CourseView />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.PassagesList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<PassagesList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddPassage}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddPassage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.EditPassageId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditPassage />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.EventsList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EventList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddEvent}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddEvent />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.EditEventId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditEvent />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.SessionList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<SessionList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddSession}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddSession />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.EditSessionId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditSession />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.MentorList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<MentorList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddMentor}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddMentor />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.EditMentorId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditMentor />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.QuizList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<QuizList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddQuiz}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddQuiz />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.EditQuizId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditQuiz />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.LearningList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<LearningList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddLearning}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddLearning />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.EditLeanringId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditLearning />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.FreeCourseList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<FreeCourseList />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.AddFreeCourse}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<AddFreeCourse />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.EditFreeCourseId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<EditFreeCourse />} />
            </ProtectedRoute>
          }
        />

        <Route
          path={URL.CoursesRequestList}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<CourseRequestList />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.CreatePreSaleId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<CreatePreSale />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.Transactions}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<Transactions />} />
            </ProtectedRoute>
          }
        />
        <Route
          path={URL.CreateAllowListId}
          element={
            <ProtectedRoute accessBy="authenticated">
              <Layout component={<CreateAllowList />} />
            </ProtectedRoute>
          }
        />
        <Route path={URL.Register} element={<Register />} />
        <Route path={URL.Page500} element={<Page505 />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </Suspense>
  )
}
